import AddToAllPages from "../../components/AddToAllPages";
import SponsorForms from "./SponsorForms"
import Settings from "./Settings";

import { useState } from "react";
import { useSelector } from "react-redux";

import "../../styles/pages/Admin/Admin.scss"

export default function Admin() {
  const [activeTab, setActiveTab] = useState('settings')
  const { userDetails } = useSelector((state) => state);
  const role = userDetails.user?.user?.profile?.role;

  return (
    <AddToAllPages>
      <div className="content-container">
        <div className="sections-buttons">
          <button
            onClick={() => setActiveTab('settings')}
            className={activeTab === 'settings' ? 'active' : ''}
          >
            Site settings
          </button>
          {role === 'Admin' && (
            <button
              onClick={() => setActiveTab('sponsor')}
              className={activeTab === 'sponsor' ? 'active' : ''}
            >
              Sponsor Forms
            </button>
          )}
        </div>
      </div>
      {activeTab === 'sponsor' && <SponsorForms />}
      {activeTab === 'settings' && <Settings />}
    </AddToAllPages>
  )
}
