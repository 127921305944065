import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  DriverAllOrderActions,
  DriverAvailableActions,
} from "../../actions/driverActions";
import { DRIVER_GET_ORDER_FAIL } from "../../constants/driverConstants";

// scss
import "../../styles/pages/Driver/HomeDriver.scss";

// Components
import DeliveryNavigationComponents from "../../components/navigation/Driver-Navigation/DriverNavigationComponents";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import formatCurrency from "../../helpers/format/formatCurrency";
import formatDateTime from "../../helpers/formatDateTime";
import { useCreateTakeOrderMutation } from "../../services/galeanaBizApi";

const HomeDriver = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const driver = JSON.parse(localStorage.getItem("DriverProfile"));
  const driver_id = driver?.id;
  const current_order = driver?.current_order;

  const [takeOrder, takeOrderResult] = useCreateTakeOrderMutation();

  const userStatus = useSelector((state) => state.userDetails);
  const { user } = userStatus;

  const role = user?.user?.profile?.role;
  const [drivers, setDrivers] = useState([]);

  const DriversAvailable = useSelector((state) => state.DriversAvailable);
  const {
    // loading: DriversLoading,
    // error: DriversError,
    Drivers,
  } = DriversAvailable;

  useEffect(() => {
    dispatch(DriverAllOrderActions());
    dispatch(DriverAvailableActions());
  }, [dispatch]);

  useEffect(() => {
    setDrivers(Drivers);
  }, [Drivers]);

  useEffect(() => {
    if (takeOrderResult.isSuccess) {
      if (role === "Admin") {
        navigate("/driver-order-in-progress");
      } else {
        navigate("/driver-order-list");
      }
    }
  }, [role, takeOrderResult.isSuccess, dispatch, navigate]);

  const assingOrder = (transaction_id) => {
    takeOrder({ driverId: driver_id, transactionId: transaction_id }).then(
      (response) => {
        if ("error" in response) {
          return;
        }
      }
    );
  };

  const AdminassingOrder = (driver_id, transaction_id) => {
    takeOrder({ driverId: driver_id, transactionId: transaction_id }).then(
      (response) => {
        if ("error" in response) {
          return;
        }
      }
    );
  };

  return (
    <DeliveryNavigationComponents>
      <div className="homeDriver">
        <div className="titleContainer">
          <div className="title">Welcome to the driver system</div>
          <div className="subtitle">Claim orders bellow</div>
        </div>

        <AllOrderList
          drivers={drivers}
          role={role}
          current_order={current_order}
          AdminassingOrder={AdminassingOrder}
          assingOrder={assingOrder}
        />
      </div>
    </DeliveryNavigationComponents>
  );
};

export default HomeDriver;

const AllOrderList = ({
  drivers,
  role,
  current_order,
  AdminassingOrder,
  assingOrder,
}) => {
  const dispatch = useDispatch();

  const [orders, setOrders] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);

  const DriverAllOrder = useSelector((state) => state.DriverAllOrder);
  const {
    loading: AllOrderLoading,
    error: AllOrderError,
    next,
    DriverOrderAll,
  } = DriverAllOrder;

  useEffect(() => {
    if (!DriverAllOrder || AllOrderLoading) {
      return;
    }
    setOrders(DriverOrderAll || []);
    setNextCursor(next || null);
  }, [DriverOrderAll, DriverAllOrder, AllOrderLoading, next]);

  const loadMoreOrders = async () => {
    if (nextCursor) {
      try {
        const response = await axios.get(nextCursor);
        setOrders((prevOrders) => [...prevOrders, ...response.data.results]);
        setNextCursor(response.data.next);
      } catch (err) {
        dispatch({
          type: DRIVER_GET_ORDER_FAIL,
          payload:
            err.response && err.response.data.detail
              ? err.response.data.detail
              : err.message,
        });
      }
    }
  };

  if (AllOrderLoading) {
    return <Loader />;
  }
  if (AllOrderError) {
    return <Message>{AllOrderError}</Message>;
  }

  return (
    <div className="orderCardsSection">
      <div className="orderCardsContainer">
        <div className="allOrdersWrapper">
          {orders.map((order, index) => {
            return (
              <div className="orderCardContainer" key={index}>
                <Link
                  to={`/driver-order/${order?.transaction_id}/`}
                  className="orderCard"
                >
                  <div className="topSection">
                    <div className="nameAndLocationContainer">
                      <div className="nameTxt">
                        {order?.user_name} {order?.last_name}
                      </div>
                      <div className="locationTxt">
                        <i className="fa-solid fa-location-dot"></i>
                        {order?.location}
                      </div>
                    </div>

                    <div className="dateTxt">
                      {formatDateTime(order?.date_issued)}
                    </div>
                  </div>
                  <div className="businessesSection">
                    <div className="title">Businesses in order:</div>

                    <div className="contentCompanies">
                      {Object.values(order?.order_companies || []).map(
                        (companies, index) => (
                          <div className="businessName" key={index}>
                            {companies?.company_name}
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  {order?.delivery_instructions ? (
                    <div className="extraInfoSection">
                      {order?.delivery_instructions}
                    </div>
                  ) : null}

                  <div className="priceContainer">
                    <span className="priceTxt">
                      {formatCurrency(order?.order_total)} MXN
                    </span>
                  </div>
                </Link>

                <SelectorOrder
                  order={order}
                  drivers={drivers}
                  role={role}
                  current_order={current_order}
                  AdminassingOrder={AdminassingOrder}
                  assingOrder={assingOrder}
                />
              </div>
            );
          })}

          {nextCursor && <button onClick={loadMoreOrders}>Load more</button>}
        </div>
      </div>
    </div>
  );
};

const SelectorOrder = ({
  order,
  drivers,
  role,
  current_order,
  AdminassingOrder,
  assingOrder,
}) => {
  const isAdmin = role === "Admin";

  const Admin = () => {
    const shouldRenderAdminSection = isAdmin && !current_order;

    if (shouldRenderAdminSection) {
      return (
        <div className="takeOrderBtnContainer">
          <select
            className="diverCTAButton"
            onChange={(e) =>
              AdminassingOrder(e.target.value, order?.transaction_id)
            }
          >
            <option value="">Assing Driver</option>
            {Object.values(drivers || []).map((driver) => (
              <option key={driver?.id} value={driver?.id}>
                {driver?.username}
              </option>
            ))}
          </select>
        </div>
      );
    }

    return null;
  };

  const Driver = () => {
    if (isAdmin) return null;

    const shouldRenderDriverSection = !current_order;

    if (shouldRenderDriverSection) {
      return (
        <div className="takeOrderBtnContainer">
          <div
            className="diverCTAButton"
            onClick={() => assingOrder(order?.transaction_id)}
          >
            Take Order
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Driver />
      <Admin />
    </>
  );
};
